import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef,
  Fragment,
} from "react";
import { AuthContext } from "../index";
import { auth, firebase } from "../firebase";
import ReCAPTCHA from "react-google-recaptcha";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { setLoggedIn, setUser } from "../redux/actions/users";

const LoginForm = ({
  isOpen,
  setIsOpen,
  isLoggedIn,
  user,
  setLoggedIn,
  setUser,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const [forgot, setForgot] = useState(false);
  const [resettingPassword, setResettingPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [verificationError, setVerificationError] = useState(false);

  const recaptchaRef = React.createRef();
  const history = useHistory();
  const location = useLocation();
  let { g_uid, hash, resetHash } = useParams();
  let btnRef = useRef();
  const [verifying, setVerifying] = useState(hash ? true : false);

  useEffect(() => {
    if (resetHash) setResettingPassword(true);
  }, [resetHash]);

  const handleForm = async (e) => {
    e.preventDefault();
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
      btnRef.current.setAttribute("value", "Logging in...");
    }

    const token = await recaptchaRef.current.executeAsync();

    let res = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/users/checkCaptcha",
      {
        token,
      }
    );

    if (!res.data.success) return;
    else {
      if (recaptchaRef.current) recaptchaRef.current.reset();
    }
    let aRes = null;
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (res) => {
        if (!auth?.currentUser?.emailVerified) {
          btnRef.current.removeAttribute("disabled");
          btnRef.current.setAttribute("value", "Login");
          setErrors({
            message:
              "Please check your e-mail and follow the link to confirm your account before login in.",
            type: 1,
          });

          return;
        }
        const token = await auth?.currentUser?.getIdToken(true);
        if (res.user) {
          res = await axios.post(
            process.env.REACT_APP_BACKEND_URL + "/users/login",
            {
              g_uid: auth?.currentUser?.uid,
              token,
              fromLogin: true,
            }
          );
          localStorage.setItem("@token", token);
          setUser(res.data);
          setLoggedIn(true);
          console.log("Location", location);
          if (location && location.state && location.state.original_route) {
            history.push(location.state.original_route);
          } else {
            history.push("/home");
          }
        }
      })
      .catch((e) => {
        localStorage.removeItem("@token");
        setUser(null);
        setLoggedIn(false);
        btnRef.current.removeAttribute("disabled");
        btnRef.current.setAttribute("value", "Login");
        console.log("ERRROOOOR", e.message);
        if (e.response && e.response.data && e.response.data.message)
          setErrors({ message: e.response.data.message });
        else if (
          e.message &&
          e.message !== "Request failed with status code 500"
        )
          setErrors({
            message: e.message, //"Your sign up request will now be processed by our team. We will get back to you shortly."
          });
        else
          setErrors({
            message:
              "Your sign up request will now be processed by our team. We will get back to you shortly.!!!!" +
              e.response.data,
          });
      });
  };

  const handleFormReset = async (e) => {
    e.preventDefault();
    try {
      let res = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/users/send_reset_password_link",
        {
          email,
        }
      );
      setErrors({
        icon: "mark_read",
        style: { color: "#212326" },
        message:
          "If this username exists in our records, you will receive an email with instructions on how to create a new password.",
      });
    } catch (e) {
      setErrors({ message: e.message });
    }
  };

  const handleFormNewPassword = async (e) => {
    e.preventDefault();

    if (!password || !password2)
      setErrors({ message: "Please complete all fields" });
    else if (password !== password2) {
      setErrors({ message: "Passwords doesn't match" });
    } else {
      const passRegexp = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
      );

      if (!passRegexp.test(password))
        setErrors({
          message:
            "Password must have at least one uppercase letter, one lowercase letter and one number",
        });
      else {
        try {
          let res = await axios.post(
            process.env.REACT_APP_BACKEND_URL + "/users/reset_password",
            {
              hash: resetHash,
              password,
            }
          );
          setPassword("");
          setResettingPassword(false);
          setErrors({
            icon: "verified_user",
            style: { color: "#212326" },
            message: "Success! You can now login.",
          });
        } catch (e) {
          setErrors({ message: e.message });
        }
      }
    }
  };

  const resendVerificationEmail = async (e) => {
    try {
      setErrors({ message: "Sending e-mail..." });
      await firebase.auth().signInWithEmailAndPassword(email, password);

      let res = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/users/resend",
        {
          g_uid: auth?.currentUser?.uid,
          token: await auth?.currentUser?.getIdToken(true),
        }
      );
      setErrors({ message: "Verification e-mail sent!" });
    } catch (e) {
      setErrors({ message: e.message });
    }
  };

  function closeModal() {
    history.push("/");
    setIsOpen(false);
  }
  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      closeModal();
    }
  }, []);

  useEffect(() => {
    if (hash) {
      (async () => {
        if (verifying) {
          await firebase.auth().signOut();
          localStorage.removeItem("@token");
          setLoggedIn(false);
          setUser(null);
          let res = await axios.post(
            process.env.REACT_APP_BACKEND_URL + "/users/verify",
            {
              g_uid,
              hash,
            }
          );
        }
      })()
        .then(() => setVerifying(false))
        .catch(() => {
          setVerificationError(true);
          setVerifying(false);
        });
    }
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div
      className="popup-wrapper"
      style={{ ...(isOpen ? { display: "flex" } : { display: "none" }) }}
      onClick={(e) => {
        if (e.target.className === "popup-wrapper") closeModal();
      }}
      onKeyPress={(e) => {
        console.log(e.key);
        if (e.key === "Escape") {
          closeModal();
        }
      }}
    >
      <div className="popup-box">
        <button className="popup-box__close" onClick={closeModal}>
          &times;
        </button>
        <h2 className="popup-box__title">ilga world monitor</h2>
        {hash && verificationError ? (
          <h3 style={{ textAlign: "center" }}>
            <br />
            Your email account has been verified successfully.
            <br />
            <br />
            This evaluation can take several working days.
            <br />
            For queries, please reach out to research@ilga.org.
          </h3>
        ) : resettingPassword ? (
          <Fragment>
            <h3 className="popup-box__subtitle">Reset password</h3>
            <form
              onSubmit={(e) => handleFormNewPassword(e)}
              className="login-form"
            >
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              />
              <input
                className="login-form__input"
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                value={password}
                disabled={verifying}
                type="password"
                placeholder="new password"
              />
              <input
                className="login-form__input"
                onChange={(e) => setPassword2(e.target.value)}
                name="password"
                value={password2}
                disabled={verifying}
                type="password"
                placeholder="repeat password"
              />

              <input
                ref={btnRef}
                className="login-form__submit"
                type="submit"
                value="Reset password"
              />
              {errors.message && (
                <div className="login-form-error" style={errors.style}>
                  {errors.icon && (
                    <img
                      src={"/assets/img/" + errors.icon + ".svg"}
                      style={{ float: "left" }}
                    />
                  )}
                  <p>{errors.message}</p>
                </div>
              )}
            </form>
          </Fragment>
        ) : forgot ? (
          <Fragment>
            <h3 className="popup-box__subtitle">Forgot password?</h3>
            <form onSubmit={(e) => handleFormReset(e)} className="login-form">
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              />
              <input
                className="login-form__input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                type="email"
                disabled={verifying}
                placeholder="E-mail Address"
              />
              <a
                href="#"
                className="forgotPassword"
                onClick={() => {
                  setErrors("");
                  setForgot(false);
                }}
              >
                Back to login.
              </a>
              <input
                ref={btnRef}
                className="login-form__submit"
                type="submit"
                value="Reset password"
              />
              {errors.message && (
                <div className="login-form-error" style={errors.style}>
                  {errors.icon && (
                    <img
                      src={"/assets/img/" + errors.icon + ".svg"}
                      style={{ float: "left" }}
                    />
                  )}
                  <p>{errors.message}</p>
                </div>
              )}
            </form>
          </Fragment>
        ) : (
          <Fragment>
            <h3 className="popup-box__subtitle">
              {!hash
                ? "Login"
                : verifying
                ? "Verifying account..."
                : !verificationError
                ? "Account verified, you can now login"
                : "Error"}
            </h3>

            <form onSubmit={(e) => handleForm(e)} className="login-form">
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              />
              <input
                className="login-form__input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                type="email"
                disabled={verifying}
                placeholder="E-mail Address"
              />
              <input
                className="login-form__input"
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                value={password}
                disabled={verifying}
                type="password"
                placeholder="password"
              />
              <a
                href="#"
                className="forgotPassword"
                onClick={() => setForgot(true)}
              >
                Forgot password? Click here.
              </a>
              <input
                ref={btnRef}
                className="login-form__submit"
                type="submit"
                disabled={verifying}
                value="Login"
              />
              {errors.message && (
                <div className="login-form-error" style={errors.style}>
                  {errors.icon && (
                    <img
                      src={"/assets/img/" + errors.icon + ".svg"}
                      style={{ float: "left" }}
                    />
                  )}
                  <p>{errors.message}</p>{" "}
                  {errors.type === 1 && (
                    <div>
                      <a
                        href="#!"
                        onClick={(e) => {
                          e.preventDefault();
                          resendVerificationEmail();
                        }}
                      >
                        Resend verification e-mail
                      </a>
                    </div>
                  )}
                </div>
              )}
            </form>
          </Fragment>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.users.logged_in,
  user: state.users.current,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setLoggedIn: (should) => dispatch(setLoggedIn(should)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
