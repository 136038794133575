import React, { useState, useContext, useCallback, useEffect, useRef, Fragment } from "react";
import { AuthContext } from "../index";
import { auth, firebase } from "../firebase";
import ReCAPTCHA from "react-google-recaptcha";
import { Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import { setLoggedIn, setUser } from "../redux/actions/users";
import { connect } from "react-redux";

const SignupForm = ({ isOpen, setIsOpen, isLoggedIn, userStore, setUserStore, setLoggedIn }) => {
  const [user, setUser] = useState("");
  const [success, setSuccess] = useState("");

  const [errors, setErrors] = useState("");
  const recaptchaRef = React.createRef();
  const history = useHistory();

  let btnRef = useRef();
  const Auth = useContext(AuthContext);
  const handleForm = async (e) => {
    e.preventDefault();

    if (
      !user.pronoun ||
      !user.firstname ||
      !user.lastname ||
      !user.email ||
      !user.password ||
      !user.is_cso ||
      (user.is_cso==1 && (!user.ilga_member || !user.cso_name)) ||
      !user.how_find ||
      (user.how_find == 4 && !user.how_find_other) ||
      !user.access_reason
    ) {
      setErrors("Please complete all fields");

      return;
    }
    if (user.password !== user.password2) {
      setErrors("Passwords doesn't match");
      return;
    }

    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
      btnRef.current.setAttribute("value", "Registering...");
    }

    const token = await recaptchaRef.current.executeAsync();

    let res = await axios.post(process.env.REACT_APP_BACKEND_URL + "/users/checkCaptcha", {
      token
    });

    if (!res.data.success) return;
    else recaptchaRef.current.reset();

    firebase
      .auth()
      .createUserWithEmailAndPassword(user.email, user.password)
      .then(async (res) => {
        const token = await auth?.currentUser?.getIdToken(true);
        if (res.user) {
          await res.user.updateProfile({
            displayName: user.firstname + " " + user.lastname
          });
          res = await axios.post(process.env.REACT_APP_BACKEND_URL + "/users/signup", {
            g_uid: auth?.currentUser?.uid,
            ...user
          });

          setSuccess(true);
        }
      })
      .catch((e) => {
        btnRef.current.removeAttribute("disabled");
        setErrors(e.message);
      });
  };

  function closeModal() {
    setIsOpen(false);
  }
  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      closeModal();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div
      className="popup-wrapper"
      style={{ ...(isOpen ? { display: "flex" } : { display: "none" }) }}
      onClick={(e) => {
        if (e.target.className === "popup-wrapper") closeModal();
      }}
      onKeyPress={(e) => {
        console.log(e.key);
        if (e.key === "Escape") {
          closeModal();
        }
      }}
    >
      <div className="popup-box">
        <button className="popup-box__close" onClick={closeModal}>
          &times;
        </button>
        <h2 className="popup-box__title">ilga world monitor</h2>
        {!success ? (
          <Fragment>
            <h3 className="popup-box__subtitle">Register</h3>
            <form onSubmit={(e) => handleForm(e)} className="login-form">
              <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} />
              <div className="signup-row">
                <div className="signup-label">Pronoun(s):</div>
                <div className="signup-input">
                  <input
                    className="register-form__input"
                    type="text"
                    
                    value={user.pronoun}
                    onChange={(e) => setUser({ ...user, pronoun: e.target.value })}
                  />
                </div>
              </div>
              <div className="signup-row">
                <div className="signup-label">Name:</div>
                <div className="signup-input">
                  <input
                    className="register-form__input"
                    type="text"
                    
                    value={user.firstname}
                    onChange={(e) => setUser({ ...user, firstname: e.target.value })}
                  />
                </div>
              </div>
              <div className="signup-row">
                <div className="signup-label">Surname:</div>
                <div className="signup-input">
                  <input
                    className="register-form__input"
                    type="text"
                    
                    value={user.lastname}
                    onChange={(e) => setUser({ ...user, lastname: e.target.value })}
                  />
                </div>
              </div>
              <div className="signup-row">
                <div className="signup-label">E-mail Address:</div>
                <div className="signup-input">
                  <input
                    className="register-form__input"
                    type="email"
                    
                    value={user.email}
                    onChange={(e) => setUser({ ...user, email: e.target.value })}
                  />
                </div>
              </div>
              <div className="signup-row">
                <div className="signup-label">Password:</div>
                <div className="signup-input">
                  <input
                    className="register-form__input"
                    type="password"
                    
                    value={user.password}
                    onChange={(e) => setUser({ ...user, password: e.target.value })}
                  />
                </div>
              </div>
              <div className="signup-row">
                <div className="signup-label">Retype Password:</div>
                <div className="signup-input">
                  <input
                    className="register-form__input"
                    type="password"
                    
                    value={user.password2}
                    onChange={(e) => setUser({ ...user, password2: e.target.value })}
                  />
                </div>
              </div>
              <div className="signup-row">
                <div className="signup-label signup-label-lg">
                  Are you affiliated with any civil society organisation?
                </div>
                <div className="signup-input signup-input-lg signup-input-radio-group">
                  <div className="signup-input signup-input-lg signup-input-radio">
                    <input
                      type="radio"
                      name="is_cso"
                      id="cso_yes"
                      className="signup-radio"
                      value={1}
                      onChange={(e) => {
                        setUser({ ...user, is_cso: e.target.value });
                      }}
                    />
                    <label for="cso_yes">Yes</label>
                  </div>
                  <div className="signup-input signup-input-lg signup-input-radio">
                    <input
                      type="radio"
                      name="is_cso"
                      id="cso_no"
                      className="signup-radio"
                      value={2}
                      onChange={(e) => {
                        setUser({ ...user, is_cso: e.target.value });
                      }}
                    />
                    <label for="cso_no">No</label>
                  </div>
                </div>
              </div>
              {user.is_cso == 1 && (
                <>
                  <div className="signup-row">
                    <div className="signup-label">Name of the organisation:</div>
                    <div className="signup-input">
                      <input
                        className="register-form__input"
                        type="text"
                        name="cso_name"
                        
                        value={user.cso_name}
                        onChange={(e) => setUser({ ...user, cso_name: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="signup-row">
                    <div className="signup-label signup-label-lg">Is your organisation a member of ILGA?</div>
                    <div className="signup-input signup-input-lg signup-input-radio-group">
                      <div className="signup-input signup-input-lg signup-input-radio">
                        <input
                          type="radio"
                          name="is_ilga_member"
                          id="is_ilga_member_yes"
                          className="signup-radio"
                          value={1}
                          onChange={(e) => setUser({ ...user, ilga_member: e.target.value })}
                        />
                        <label for="cso_yes">Yes</label>
                      </div>
                      <div className="signup-input signup-input-lg signup-input-radio">
                        <input
                          type="radio"
                          name="is_ilga_member"
                          id="is_ilga_member_no"
                          className="signup-radio"
                          value={2}
                          onChange={(e) => setUser({ ...user, ilga_member: e.target.value })}
                        />
                        <label for="cso_no">No</label>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="signup-row">
                <div className="signup-label">How did you find about the IWM?</div>
                <div className="signup-input">
                  <select
                    className="register-form__input register-form__select"
                    type="text"
                    name="how_find"
                    value={user.how_find}
                    onChange={(e) => setUser({ ...user, how_find: e.target.value })}
                  >
                    <option value="0">Select...</option>
                    <option value="1">a. Personal invitation to join</option>
                    <option value="2">b. ILGA Newsletter</option>
                    <option value="3">c. Recommended by friend/colleague</option>
                    <option value="4">d. Other</option>
                  </select>
                </div>
              </div>
              {user.how_find == 4 && (
                <div className="signup-row">
                  <div className="signup-label">Other:</div>
                  <div className="signup-input">
                    <input
                      className="register-form__input"
                      type="text"
                      name="how_find_other"
                      
                      value={user.how_find_other}
                      onChange={(e) => setUser({ ...user, how_find_other: e.target.value })}
                    />
                  </div>
                </div>
              )}
              <div className="signup-row">
                <div className="signup-label">Why do you want to have access to the ILGA World Monitor?</div>
                <div className="signup-input">
                  <textarea
                    className="register-form__input register-form__textarea"
                    type="text"
                    rows="3"
                    name="access_reason"
                    
                    onChange={(e) => setUser({ ...user, access_reason: e.target.value })}
                  >
                    {user.access_reason}
                  </textarea>
                </div>
              </div>
              <div className="signup-row">
                <div className="signup-label-full">Please provide a reference (optional):</div>
              </div>
              <div className="signup-row">
                <div className="signup-label">Reference name:</div>
                <div className="signup-input">
                  <input
                    className="register-form__input"
                    type="text"
                    name="reference_name"
                    
                    value={user.reference_name}
                    onChange={(e) => setUser({ ...user, reference_name: e.target.value })}
                  />
                </div>
              </div>
              <div className="signup-row">
                <div className="signup-label">Reference e-mail:</div>
                <div className="signup-input">
                  <input
                    className="register-form__input"
                    type="email"
                    name="reference_email"
                    
                    value={user.reference_email}
                    onChange={(e) => setUser({ ...user, reference_email: e.target.value })}
                  />
                </div>
              </div>

              <input className="login-form__submit" type="submit" value="Sign Up" ref={btnRef} />
              {errors && <div className="login-form-error">{errors}</div>}
            </form>
          </Fragment>
        ) : (
          <div>
            <h3 className="popup-box__subtitle popup-box__subtitle--center">Register</h3>{" "}
            <p className="popup-box__text">
              <b>Thank you for submitting your request to sign up to the ILGA World Monitor.</b>
              <br />
              Please check your email and verify your address. Make sure to check your spam folder.
            </p>
            <button className="login-form__submit" onClick={closeModal}>
              Accept
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.users.logged_in,
  userStore: state.users.current
});

const mapDispatchToProps = (dispatch) => ({
  setUserStore: (user) => dispatch(setUser(user)),
  setLoggedIn: (should) => dispatch(setLoggedIn(should))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
