import React, { useState, useContext, useRef, useEffect } from "react";

import PublicHomeLayout from "../Layout/PublicHomeLayout";

import { AuthContext } from "..";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  getLatestMouGeneral,
  getLatestOtherGeneral,
  getWorldStats,
} from "../redux/actions/articles";
import { getJurisdictions, getRegions } from "../redux/actions/related";

import CountUp from "react-countup";
import FlagImg from "../Components/FlagImg";
//import useInterval from "../Lib/useInterval";
import { AutoScroller } from "../Lib/AutoScroller";
import { Fragment } from "react";
const PublicHome = ({
  all_number,
  regions,
  getRegions,
  getWorldStats,
  jurisdictions,
  getJurisdictions,
  isLoggedIn,
  user,
  getLatestMouGeneral,
  latest_mou_general,
  getLatestOtherGeneral,
  latest_other_general,
}) => {
  const history = useHistory();
  let { g_uid, hash, resetHash } = useParams();

  const [openLogin, setOpenLogin] = useState(false);

  useEffect(() => {
    if (!regions || !regions.length) getRegions();
    if (!all_number) getWorldStats();
    if (!jurisdictions || !jurisdictions.length) getJurisdictions();
    if (!latest_mou_general || !latest_mou_general.length)
      getLatestMouGeneral();

    if (g_uid && hash || resetHash) {
      setOpenLogin(true);
    }
  }, []);
  /*
  useInterval(() => {
    setCurrentNumber(currentNumber + 1);
  }, 2000);
  */
  return (
    <PublicHomeLayout
      showControls={false}
      openLogin={openLogin}
      setOpenLogin={setOpenLogin}
    >
      <div className="public-home">
        {regions && regions.length > 0 && (
          <div className="cards-container cards-container--public-home">
            <div className="card card--100 live-numbers">
              <div className="live-numbers__header">
                <h2>
                  {all_number > 0 ? (
                    <CountUp
                      separator="’"
                      end={all_number}
                      start={5000}
                      duration={8}
                    />
                  ) : (
                    <CountUp separator="’" end={5000} start={0} duration={5} />
                  )}
                </h2>
                <b>World total</b>
              </div>

              <ul className="live-numbers__list">
                {regions.map((region, index) => (
                  <li
                    key={index}
                    className={`live-numbers__list-item live-numbers__list-item--${region.color}`}
                  >
                    <p>{region.name}</p>
                    <b>
                      {" "}
                      <CountUp
                        separator="’"
                        end={region.n_approved}
                        start={0}
                        duration={5}
                      />
                    </b>
                  </li>
                ))}
              </ul>
            </div>

            <div className="card card--100 public-home-text">
              <h3>International SOGIESC reporting, all under one roof.</h3>
              <p>
                The ILGA World Monitor keeps track of events relevant to our
                communities around the globe, as reported by local, regional and
                international media outlets, civil society organisations,
                governmental agencies, human rights bodies, academia, and other
                relevant stakeholders.
                <br />
                <br />
                This project will provide our Research Team, and you, with an
                easily accessible and truly expansive collection of digital
                content about sexual orientation, gender identity and
                expression, and sex characteristics.
                <br />
                This tool allows users to quickly scan for relevant developments
                in areas of particular interest, search for articles pertaining
                to specific countries, identities or events, or zoom out and
                view trends from a broader, more holistic perspective.
                <br />
                <br />
                The internet is vast and can at times feel like a maze, but with
                the ILGA World Monitor research and learning about our
                communities is easier than ever. After all,{" "}
                <b>an informed community is a strong community.</b>
              </p>
            </div>
          </div>
        )}

        {latest_mou_general &&
          latest_mou_general.length > 0 &&
          jurisdictions &&
          jurisdictions.length > 0 && (
            <AutoScroller
              speed={3000}
              initial={5}
              classes={"updates-container"}
              pauseOver={true}
              unit={9}
              subclasses="update"
              subclassesFn={(i, last) =>
                i - 1 - last === 0
                  ? "update--high"
                  : i - 1 - last === -1 || i - 1 - last === 1
                  ? "update--mid"
                  : "update--low"
              }
            >
              {latest_mou_general.map((art, i) => (
                <Fragment key={i}>
                  {art.j && (
                    <FlagImg
                      classes="update__flag"
                      code={art.j.split(",")[0]}
                      name={art.j.split(",")[0]}
                    />
                  )}
                  {art.j && (
                    <p className="update__country">
                      {
                        jurisdictions.find(
                          (j) => j.country_code === art.j.split(",")[0]
                        ).name
                      }
                    </p>
                  )}
                  <h2 className="update__title">{art.th ? art.th : art.h}</h2>
                  <a
                    href={art.u}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    className="update__button"
                  >
                    &nbsp;
                  </a>
                </Fragment>
              ))}
            </AutoScroller>
          )}
        <button
          onClick={(e) => {
            !isLoggedIn ? setOpenLogin(true) : history.push("/home");
          }}
          className="public-home__button"
        >
          Enter
        </button>
      </div>
    </PublicHomeLayout>
  );
};

const mapStateToProps = (state) => ({
  all_number: state.articles.stats.all,
  regions: state.related.regions,
  jurisdictions: state.related.jurisdictions,
  isLoggedIn: state.users.logged_in,
  user: state.users.current,
  latest_mou_general: state.articles.latest_mou_general,
  latest_other_general: state.articles.latest_other_general,
});

const mapDispatchToProps = (dispatch) => ({
  getWorldStats: () => dispatch(getWorldStats()),
  getRegions: () => dispatch(getRegions()),
  getJurisdictions: () => dispatch(getJurisdictions()),
  getLatestMouGeneral: () => dispatch(getLatestMouGeneral()),
  getLatestOtherGeneral: () => dispatch(getLatestOtherGeneral()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicHome);
