import * as clipboard from "clipboard-polyfill";
import { utc } from "moment-timezone";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";

moment.locale('en');

function copyToClip(str) {
  function listener(e) {
    e.clipboardData.setData("text/html", str);
    e.clipboardData.setData("text/plain", str.replace(/(<([^>]+)>)/gi, ""));
    e.preventDefault();
  }
  document.addEventListener("copy", listener);
  let ret = document.execCommand("copy");
  document.removeEventListener("copy", listener);
  if (ret) return ret;
  else {
    if ("clipboard" in navigator && "ClipboardItem" in window) {
      const contentH = str;
      const blobH = new Blob([contentH], { type: "text/html" });
      const content = str.replace(/(<([^>]+)>)/gi, "");
      const blob = new Blob([content], { type: "text/plain" });
      const clipboardItemH = new window.ClipboardItem({
        "text/html": blobH,
        "text/plain": blob,
      });
      return navigator.clipboard.write([clipboardItemH]);
    } else {
      return false;
    }
  }
}

export const citeOne = async (article, jurisdictions, inSpanish = false) => {
  let cite;
  
  if (inSpanish) {

    if (article.l !== "Spanish") {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          "/articles/get_spanish_entry/" +
          article.id
      );
      if (responseData && responseData.data && responseData.data.article) {
        article = responseData.data.article;
      }
    }
    cite =
      '“<a href="' +
      article.u +
      '">' +
      article.h +
      "</a>”" +
      (article.l !== "Spanish" && article.th
        ? " [" + article.th + "], "
        : ", ") +
      "<em>" +
      article.s +
      "</em>, " +
      moment(utc(article.da)).locale("es").format("DD [de] MMMM [de] YYYY") +
      ".";
  } else {
    cite =
      '“<a href="' +
      article.u +
      '">' +
      article.h +
      "</a>”" +
      (article.th ? " [" + article.th + "], " : ", ") +
      "<em>" +
      article.s +
      "</em>, " +
      utc(article.da).format("DD MMMM YYYY") +
      ".";
  }

  copyToClip(cite);
  return cite;
};

export const citeMultiple = async (articles, jurisdictions) => {
  let cite = "";
  articles.forEach((article) => {
    cite +=
      (cite ? "<p>" : "<p>") +
      '“<a href="' +
      article.u +
      '">' +
      article.h +
      "</a>”" +
      (article.th ? " [" + article.th + "], " : ", ") +
      "<em>" +
      article.s +
      "</em>, " +
      utc(article.da).format("DD MMMM YYYY") +
      ".</p>";
  });

  copyToClip(cite);
};
