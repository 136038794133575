import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  Fragment,
} from "react";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import { useHistory } from "react-router-dom";

import "./SearchBar.css";
import SearchSelect from "./SearchSelect";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

import {
  setCurrentSearch,
  resetCurrentSearch,
  executeSearch,
  addSavedSearch,
  getSavedSearches,
  updateSavedSearch,
} from "../redux/actions/search";
import {
  getLanguages,
  getJurisdictions,
  getSourceNames,
  getSourceTypes,
  getTags,
} from "../redux/actions/related";
import SearchTag from "./SearchTag";
import SaveSearchPopup from "./SaveSearchPopup";
import { jurSpecialName } from "../Lib/WMHelper";

const ValueContainer = ({ children, getValue, ...props }) => {
  var values = getValue();
  var valueLabel = "";

  if (values.length > 0)
    valueLabel += props.selectProps.getOptionLabel(values[0]);
  if (values.length > 1) valueLabel = values.length + ` selected...`;

  // Keep standard placeholder and input from react-select
  var childsToRender = React.Children.toArray(children).filter(
    (child) =>
      ["Input", "DummyInput", "Placeholder"].indexOf(child.type.name) >= 0
  );

  return (
    <components.ValueContainer {...props}>
      {!props.selectProps.inputValue && valueLabel}
      {childsToRender}
    </components.ValueContainer>
  );
};

const noFilter = (theSearch) => {
  return (
    (!theSearch.jurisdictions || !theSearch.jurisdictions.length) &&
    (!theSearch.tags || !theSearch.tags.length) &&
    (!theSearch.languages || !theSearch.languages.length) &&
    (!theSearch.sources || !theSearch.sources.length) &&
    (!theSearch.source_types || !theSearch.source_types.length) &&
    (!theSearch.source_locations || !theSearch.source_locations.length) &&
    !theSearch.query
  );
};

const SearchBar = ({
  currentSearch,
  setCurrentSearch,
  languages,
  getLanguages,
  jurisdictions,
  getJurisdictions,
  sourceNames,
  getSourceNames,
  tags,
  getTags,
  sourceTypes,
  getSourceTypes,
  resetCurrentSearch,
  executeSearch,
  addSavedSearch,
  saving,
  saved,
  savedSearches,
  getSavedSearches,
  updateSavedSearch,
  setArticlesSelected,
}) => {
  const history = useHistory();
  const [savePopupOpen, setSavePopupOpen] = useState(false);

  useEffect(() => {
    if (!languages || !languages.length) getLanguages();
    if (!jurisdictions || !jurisdictions.length) getJurisdictions();
    if (!sourceNames || !sourceNames.length) getSourceNames();
    if (!sourceTypes || !sourceTypes.length) getSourceTypes();
    if (!savedSearches || !savedSearches.length) getSavedSearches();
  }, []);

  return (
    <div className="filters-container">
      <div className="filters">
        <SearchSelect
          options={jurisdictions
            .map((j) => ({
              ...j,
              name: jurSpecialName(j),
            }))
            .concat(
              [{ id: "ASI", name: "Asia (all countries)" }],
              [{ id: "EUR", name: "Europe (all countries)" }],
              [{ id: "AFR", name: "Africa (all countries)" }],
              [{ id: "LAT", name: "Latin America (all countries)" }],
              [{ id: "NAM", name: "North America (all countries)" }],
              [{ id: "OCE", name: "Oceania (all countries)" }]
            )
            .sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })}
          placeholder="Jurisdictions"
          plural="jurisdictions"
          color="#d42929"
          type="jurisdictions"
          setCurrentSearch={setCurrentSearch}
          currentSearch={currentSearch}
          preprocess={(value) => {
            if (!value) return value;
            let moreJurs = [];
            if (value.find((v) => v.id === "AFR")) {
              moreJurs = moreJurs.concat(
                jurisdictions.filter(
                  (j) =>
                    j.region_id === "AFR" && !value.find((v) => v.id === j.id)
                )
              );
            }
            if (value.find((v) => v.id === "ASI")) {
              moreJurs = moreJurs.concat(
                jurisdictions.filter(
                  (j) =>
                    j.region_id === "ASI" && !value.find((v) => v.id === j.id)
                )
              );
            }
            if (value.find((v) => v.id === "EUR")) {
              moreJurs = moreJurs.concat(
                jurisdictions.filter(
                  (j) =>
                    j.region_id === "EUR" && !value.find((v) => v.id === j.id)
                )
              );
            }
            if (value.find((v) => v.id === "LAT")) {
              moreJurs = moreJurs.concat(
                jurisdictions.filter(
                  (j) =>
                    j.region_id === "LAT" && !value.find((v) => v.id === j.id)
                )
              );
            }
            if (value.find((v) => v.id === "NAM")) {
              moreJurs = moreJurs.concat(
                jurisdictions.filter(
                  (j) =>
                    j.region_id === "NAM" && !value.find((v) => v.id === j.id)
                )
              );
            }
            if (value.find((v) => v.id === "OCE")) {
              moreJurs = moreJurs.concat(
                jurisdictions.filter(
                  (j) =>
                    j.region_id === "OCE" && !value.find((v) => v.id === j.id)
                )
              );
            }
            return value
              .filter(
                (v) =>
                  v.id !== "AFR" &&
                  v.id !== "ASI" &&
                  v.id !== "EUR" &&
                  v.id !== "LAT" &&
                  v.id !== "NAM" &&
                  v.id !== "OCE"
              )
              .concat(moreJurs);
          }}
        />

        <SearchSelect
          options={tags}
          placeholder="Topics"
          plural="topics"
          color="#f76b1c"
          type="tags"
          setCurrentSearch={setCurrentSearch}
          currentSearch={currentSearch}
        />
        <SearchSelect
          options={[{ id: "all", name: "All Languages" }].concat(languages)}
          placeholder="Languages"
          plural="languages"
          color="#ffbb00"
          type="languages"
          setCurrentSearch={setCurrentSearch}
          currentSearch={currentSearch}
          preprocess={(value) => {
            if (value && value.length > 0 && value.find((v) => v.id === "all"))
              return languages;
            return value;
          }}
        />
        <SearchSelect
          options={sourceNames}
          placeholder="Sources"
          color="#22b82b"
          plural="sources"
          type="sources"
          setCurrentSearch={setCurrentSearch}
          currentSearch={currentSearch}
        />
        <SearchSelect
          options={sourceTypes}
          placeholder="Source Types"
          color="#008ef8"
          plural="source types"
          type="source_types"
          setCurrentSearch={setCurrentSearch}
          currentSearch={currentSearch}
        />
        <SearchSelect
          options={jurisdictions}
          placeholder="Source Locations"
          color="#702aee"
          plural="source locations"
          type="source_locations"
          setCurrentSearch={setCurrentSearch}
          currentSearch={currentSearch}
        />
      </div>
      <div className="search-and-tags-container">
        <input
          type="text"
          className="search-and-tags-container__searchbar"
          placeholder="Search by term or boolean query"
          value={currentSearch.query}
          onChange={(e) =>
            setCurrentSearch({
              ...currentSearch,
              query: e.target.value,
              ...(e.target.value ? { sortBy: "re" } : { sortBy: "dd" }),
            })
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              executeSearch(currentSearch);
              history.push("/search-results");
            }
          }}
        />
        <div
          className="search-and-tags-container__tooltip"
          data-tip='Search terms. Boolean search accepted, for example: "Donald Trump" "Supreme Court" ~COVID'
        ></div>
        <div className="search-and-tags-container__tags">
          {currentSearch.jurisdictions &&
            currentSearch.jurisdictions.length > 0 &&
            currentSearch.jurisdictions.map((c) => (
              <SearchTag
                name={c.name}
                id={c.id}
                color="red"
                type="jurisdictions"
                setCurrentSearch={setCurrentSearch}
                currentSearch={currentSearch}
              />
            ))}

          {currentSearch.tags &&
            currentSearch.tags.length > 0 &&
            currentSearch.tags.map((c) => (
              <SearchTag
                name={c.name}
                id={c.id}
                color="orange"
                type="tags"
                setCurrentSearch={setCurrentSearch}
                currentSearch={currentSearch}
              />
            ))}
          {currentSearch.languages &&
            currentSearch.languages.length > 0 &&
            currentSearch.languages.map((c) => (
              <SearchTag
                name={c.name}
                id={c.id}
                color="yellow"
                type="languages"
                setCurrentSearch={setCurrentSearch}
                currentSearch={currentSearch}
              />
            ))}
          {currentSearch.sources &&
            currentSearch.sources.length > 0 &&
            currentSearch.sources.map((c) => (
              <SearchTag
                name={c.name}
                id={c.id}
                color="green"
                type="sources"
                setCurrentSearch={setCurrentSearch}
                currentSearch={currentSearch}
              />
            ))}
          {currentSearch.source_types &&
            currentSearch.source_types.length > 0 &&
            currentSearch.source_types.map((c) => (
              <SearchTag
                name={c.name}
                id={c.id}
                color="blue"
                type="source_types"
                setCurrentSearch={setCurrentSearch}
                currentSearch={currentSearch}
              />
            ))}
          {currentSearch.source_locations &&
            currentSearch.source_locations.length > 0 &&
            currentSearch.source_locations.map((c) => (
              <SearchTag
                name={c.name}
                id={c.id}
                color="purple"
                type="source_locations"
                setCurrentSearch={setCurrentSearch}
                currentSearch={currentSearch}
              />
            ))}
        </div>
      </div>
      <div className="filters-container-buttons__buttons">
        <div className="filters-container__buttons_left">
          <button
            className="filters-container__button--apply"
            disabled={noFilter(currentSearch) || savePopupOpen}
            style={{
              ...((noFilter(currentSearch) || savePopupOpen) && {
                backgroundColor: "gray",
              }),
            }}
            onClick={() => {
              setSavePopupOpen(true);
            }}
          >
            Save search criteria
          </button>
        </div>
        <div className="filters-container__buttons">
          <button
            className="filters-container__button"
            onClick={() => {
              if (setArticlesSelected) setArticlesSelected([]);
              resetCurrentSearch();
            }}
          >
            Clear all
          </button>
          <button
            className="filters-container__button--apply"
            onClick={() => {
              if (setArticlesSelected) setArticlesSelected([]);
              executeSearch(currentSearch);
              history.push("/search-results");
            }}
          >
            Search
          </button>
        </div>
      </div>

      <SaveSearchPopup
        isOpen={savePopupOpen}
        setIsOpen={setSavePopupOpen}
        savedSearches={savedSearches}
        addSavedSearch={addSavedSearch}
        currentSearch={currentSearch}
        updateSavedSearch={updateSavedSearch}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentSearch: state.search.current,
  languages: state.related.languages,
  jurisdictions: state.related.jurisdictions,
  sourceNames: state.related.source_names,
  sourceTypes: state.related.source_types,

  saving: state.search.saving,
  saved: state.search.saved,
  savedSearches: state.search.savedSearches,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentSearch: (search) => dispatch(setCurrentSearch(search)),
  resetCurrentSearch: (search) => dispatch(resetCurrentSearch(search)),
  getLanguages: () => dispatch(getLanguages()),
  getJurisdictions: () => dispatch(getJurisdictions()),
  getSourceNames: () => dispatch(getSourceNames()),

  getSourceTypes: () => dispatch(getSourceTypes()),
  executeSearch: (search) => dispatch(executeSearch(search)),
  addSavedSearch: (search, name, update_user) =>
    dispatch(addSavedSearch(search, name, update_user)),
  updateSavedSearch: (search, id, update_user) =>
    dispatch(updateSavedSearch(search, id, update_user)),
  getSavedSearches: () => dispatch(getSavedSearches()),
});
export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
