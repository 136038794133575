import React, { Fragment, useEffect, useState } from "react";
import { Transition } from "react-transition-group";
import "react-toastify/dist/ReactToastify.css";
import "./MainLayout.css";
import "./MainLayoutExtra.css";

import { Link, useHistory } from "react-router-dom";
import LoginForm from "../Components/LoginForm";

import { auth, firebase } from "../firebase";
import SignupForm from "../Components/SignupForm";

import { useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";

import { ToastContainer, toast } from "react-toastify";

import { citeMultiple } from "../Lib/Cite";
import axios from "axios";
import SharePopup from "../Components/SharePopup";

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0
};

const transitionStyles = {
  entering: { opacity: 0, display: "block" },
  entered: { opacity: 1, display: "block" },
  exited: { opacity: 0, display: "none" }
};

const Fade = ({ in: inProp, children }) => (
  <Transition
    in={inProp}
    timeout={duration}
    onEntered={() =>
      (document.getElementsByClassName("main-nav")[0].style.height = "30rem")
    }
    onExited={() =>
      (document.getElementsByClassName("main-nav")[0].style.height = "17rem")
    }
  >
    {(state) => (
      <div
        style={{
          ...defaultStyle,
          ...transitionStyles[state]
        }}
      >
        {children}
      </div>
    )}
  </Transition>
);

function MainLayout({
  children,
  showControls,
  user,
  isLoggedIn,
  articlesSelected,
  setArticlesSelected,
  alert,
  setAlert,
  jurisdictions,
  sharePopupOpen,
  setSharePopupOpen,
  setSaveFolderPopupOpen
}) {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (alert) {
      toast(<div dangerouslySetInnerHTML={{ __html: alert }}></div>, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setAlert("");
    }
  }, [alert]);

  useEffect(() => {
    [
      "/assets/svgs/cite-multiple.svg",
      "/assets/svgs/save-add.svg",
      "/assets/svgs/share.svg"
    ].forEach((i) => (new Image().src = i));
  }, []);

  const [loginIsOpen, setLoginIsOpen] = React.useState(
    location.state === "openLogin" ? true : false
  );
  const [signupIsOpen, setSignupIsOpen] = React.useState(false);

  const logout = async () => {
    await firebase.auth().signOut();
    localStorage.removeItem("@token");

    history.push({ pathname: "/", state: "logout" });
  };

  return (
    <Fragment>
      <header className="header header--internal">
        <div className="header__balance"></div>
        <Link to="/" className="no-underline">
          <h1 className="header__title">
            <img src="/assets/img/wm_logo_60.png" alt="Logo" className="logo" />
          </h1>
        </Link>
        <div className="header__buttons">
          {isLoggedIn && user && (
            <Fragment>
              <Link className="header__profile-link" to="/my-profile">
                {(user.firstname + " " + user.lastname)
                  .toUpperCase()
                  .split(" ")
                  .map((n) => n[0])
                  .join("")}
              </Link>
              <Link className="header__logout-btn" href="#" onClick={logout}>
                <img
                  src="assets/img/login-btn.png"
                  alt=""
                  className="header__logout-btn-image"
                />
              </Link>
            </Fragment>
          )}
        </div>
      </header>
      {showControls && (
        <nav className="main-nav">
          <ul className="main-nav-list">
            <li className="main-nav-list__item">
              <Link className="main-nav-list__link" to="/home" title="Home">
                <img
                  src="/assets/svgs/home.svg"
                  alt="Home"
                  title="Home"
                  width="100%"
                />
              </Link>
            </li>
            <li className="main-nav-list__item main-nav-list__item--bookmark">
              <Link
                className="main-nav-list__link"
                to="/saved-searches"
                title="My Saved Search Criteria"
              ></Link>
            </li>
            <li className="main-nav-list__item main-nav-list__item--save">
              <Link
                className="main-nav-list__link"
                to="/my-folders"
                title="My Folders"
              ></Link>
            </li>
            <Fade in={articlesSelected && articlesSelected.length >= 1} />
            <Fade
              in={articlesSelected && articlesSelected.length >= 1}
              timeout={500}
            >
              <Fragment>
                <li className="main-nav-list__item main-nav-list__item--share">
                  <Link
                    className="main-nav-list__link"
                    to="#!"
                    onClick={async (e) => {
                      setSharePopupOpen(true);
                    }}
                    title="Share"
                  ></Link>
                </li>
                <li className="main-nav-list__item main-nav-list__item--save-add">
                  <Link
                    className="main-nav-list__link"
                    onClick={async (e) => {
                      setSaveFolderPopupOpen(true);
                    }}
                    title="Save entries"
                  ></Link>
                </li>
                <li className="main-nav-list__item main-nav-list__item--cite-multiple">
                  <Link
                    to="#!"
                    className="main-nav-list__link"
                    onClick={(e) => {
                      console.log(articlesSelected);
                      e.preventDefault();
                      citeMultiple(articlesSelected, jurisdictions).then(() => {
                        setArticlesSelected([]);
                        setAlert(
                          "<strong>" +
                            articlesSelected.length +
                            " entries</strong> copied to the clipboard"
                        );
                      });
                    }}
                  ></Link>
                </li>
              </Fragment>
            </Fade>
          </ul>
        </nav>
      )}

      <section className="page-content">
        {user && articlesSelected && (
          <SharePopup
            isOpen={sharePopupOpen}
            setIsOpen={setSharePopupOpen}
            articles={articlesSelected}
            addEmailAddress={"addEmailAddress"}
            user={user}
          />
        )}
        {children}
      </section>

      <footer className="footer">
        <div className="footer__links">
          <a href="https://ilga.org/about-us/contacts" target="_blank">
            Contact Us
          </a>
          &nbsp;|&nbsp;
          <a href="https://ilga.org/" target="_blank">
            ILGA World Website
          </a>
          &nbsp;|&nbsp;
          <Link to="/terms-and-conditions">Terms and Conditions</Link>
          &nbsp;|&nbsp;
          <Link to="/help-faq">Help & FAQ</Link>
        </div>
        <div className="footer__copyright">
          © 2017 - {new Date().getFullYear()} ILGA World - The International
          Lesbian, Gay, Bisexual, Trans and Intersex Association
        </div>
      </footer>
      {(!isLoggedIn || !user) && (
        <LoginForm isOpen={loginIsOpen} setIsOpen={setLoginIsOpen} />
      )}
      {(!isLoggedIn || !user) && (
        <SignupForm isOpen={signupIsOpen} setIsOpen={setSignupIsOpen} />
      )}
      <ReactTooltip />
      <ToastContainer />
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.users.logged_in,
  user: state.users.current
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
