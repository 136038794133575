import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  Fragment,
} from "react";
import ChildEntry from "./ChildEntry";
import { utc } from "moment-timezone";
import FlagImg from "./FlagImg";
import { Link, useHistory } from "react-router-dom";

import { citeOne } from "../Lib/Cite";
import { updateSavedFolder } from "../redux/actions/search";
import ReactTooltip from "react-tooltip";

const Entry = ({
  article,
  languages,
  jurisdictions,
  sourceNames,
  tags,
  sourceTypes,
  toggleDaughters,
  loading_daughters,
  group,
  setAlert,
  addArticlesSelected,
  removeArticlesSelected,
  removeFromSavedFolder,
  setArticlesSelected,
  articlesSelected,
  setSharePopupOpen,
  reportArticle,
  setReportArticle,
  noselect,
  saveFolderPopupOpen,
  setSaveFolderPopupOpen,
  fromFolders,
  setArticleUsed,
  selectAll,
}) => {
  const history = useHistory();
  const [articleTmp, setArticleTmp] = useState(article);
  const sourceType = sourceTypes.find((st) => st.name === article.st);
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [articleTmp]);
  return (
    <div className="entry">
      <div
        className={
          "main-entry" +
          (fromFolders && articleTmp && articleTmp.used === 1
            ? " main-entry-used"
            : article.pld
            ? " pld-entry"
            : sourceType.id === 7 || sourceType.id === 14
            ? " dog-entry"
            : "")
        }
      >
        {!noselect && (
          <input
            type="checkbox"
            id=""
            value="1"
            checked={articlesSelected.find((a) => a.id === article.id)}
            className="entry__checkbox"
            onClick={(e) => {
              if (!e.ctrlKey && !e.metaKey && selectAll) {
                e.target.checked
                  ? addArticlesSelected(article)
                  : removeArticlesSelected(article);
              } else {
                selectAll();
              }
            }}
          />
        )}
        {fromFolders && articleTmp.used === 0 && (
          <div
            className="mark-used"
            onClick={(e) =>
              setArticleUsed(fromFolders, article.id, 1).then(() => {
                setArticleTmp({ ...articleTmp, used: 1 });
                ReactTooltip.hide();
              })
            }
          >
            <span class="dot" data-tip="Mark as used"></span>
          </div>
        )}
        {fromFolders && articleTmp.used === 1 && (
          <div
            className="mark-used"
            onClick={(e) =>
              setArticleUsed(fromFolders, article.id, 0).then(() => {
                setArticleTmp({ ...articleTmp, used: 0 });
                ReactTooltip.hide();
              })
            }
          >
            <span class="dot dot-used"></span> USED
          </div>
        )}
        <div className="main-entry__col main-entry__col--country">
          {article.j ? (
            article.j.split(",").map((c_id, index) => (
              <div className="entry__country-container">
                <FlagImg
                  classes="entry__country-flag"
                  code={c_id}
                  name={c_id}
                />

                <Link
                  to={
                    "/jurisdictions/" +
                    jurisdictions.find((j) => j.country_code === c_id).slug
                  }
                  className="entry__country-name"
                >
                  {jurisdictions.find((j) => j.country_code === c_id).name}
                </Link>
              </div>
            ))
          ) : (
            <div className="entry__country-container">
              <FlagImg
                classes="entry__country-flag"
                code={article.c}
                name={article.c}
              />
              <b className="entry__country-name">
                {jurisdictions.find((j) => j.country_code === article.c).name}
              </b>
            </div>
          )}
        </div>
        <div className="main-entry__col">
          <p className="entry__date">{utc(article.da).format("DD MMM YYYY")}</p>
          <h3
            className="entry__title entry__title--margin"
            onClick={(e) => {
              if (article.l !== "English")
                window.open(
                  "http://translate.google.com/translate?js=n&sl=auto&tl=en&u=" +
                    article.u,
                  "_blank",
                  "noopener,noreferrer"
                );
              else {
                window.open(article.u, "_blank", "noopener,noreferrer");
              }
            }}
          >
            {article.th && article.th !== article.h ? article.th : article.h}{" "}
          </h3>
          {article.th && article.th !== article.h && (
            <h4
              className="entry__title entry__title--translation"
              onClick={(e) => {
                window.open(article.u, "_blank", "noopener,noreferrer");
              }}
            >
              {article.h}
            </h4>
          )}
          <p className="entry__content">{article.d}</p>
        </div>
        <div className="main-entry__col main-entry__col--meta">
          <p>
            <span className="entry__meta entry__meta--italic">{article.s}</span>{" "}
            |{" "}
            <span
              className="entry__meta--highlighted"
              style={{ fontStyle: "normal" }}
            >
              {sourceTypes.find((st) => st.name === article.st).code}
            </span>
          </p>
          <p className="entry__meta entry__meta--flex">
            <FlagImg classes="entry__country-flag--small" code={article.c} />{" "}
            <span className="entry__meta--bold">{article.c}</span>&nbsp; -{" "}
            {article.l}
          </p>
          {article.daughtersCount > 0 && (
            <label className="entry__meta" for="entry-id-number-1">
              <span className="entry__meta--italic">Reiterations:</span>{" "}
              <span
                className="entry__meta--highlighted"
                onClick={(e) => toggleDaughters(article, group)}
              >
                {article.daughtersCount} (display)
              </span>
            </label>
          )}
        </div>
        <div className="main-entry__col main-entry__col--tags">
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {article.t &&
              article.t.split(",").map(
                (t_id) =>
                  tags.find((t) => t.id == t_id) && (
                    <p
                      className="entry__tag"
                      onClick={(e) =>
                        history.push(
                          "/topics/" + tags.find((t) => t.id == t_id).slug
                        )
                      }
                    >
                      {tags.find((t) => t.id == t_id).name_upper}
                    </p>
                  )
              )}
          </div>
        </div>
        <div className="main-entry__col main-entry__col--actions">
          <button
            className="entry__action"
            onClick={async (e) => {
              //setArticlesSelected([]);
              setSharePopupOpen(article);
            }}
          >
            <img
              src="/assets/svgs/share.svg"
              alt="Share entry"
              title="Share entry"
              width="100%"
              onMouseOver={(e) => {
                e.target.src = "/assets/svgs/share--hover.svg";
              }}
              onMouseOut={(e) => {
                e.target.src = "/assets/svgs/share.svg";
              }}
            />
          </button>
          {!fromFolders ? (
            <button
              className="entry__action entry__action--save"
              onClick={async (e) => {
                //setArticlesSelected([]);
                setSaveFolderPopupOpen(article);
              }}
            >
              <img
                src="/assets/svgs/save.svg"
                alt="Add entry to folder"
                title="Add entry to folder"
                width="100%"
                onMouseOver={(e) => {
                  e.target.src = "/assets/svgs/save--hover.svg";
                }}
                onMouseOut={(e) => {
                  e.target.src = "/assets/svgs/save.svg";
                }}
              />
            </button>
          ) : (
            <button
              className="entry__action"
              onClick={async (e) => {
                removeFromSavedFolder(article.id);
              }}
            >
              <img
                src="/assets/svgs/trash.svg"
                alt="Remove entry"
                title="Remove entry"
                width="100%"
                onMouseOver={(e) => {
                  e.target.src = "/assets/svgs/trash--hover.svg";
                }}
                onMouseOut={(e) => {
                  e.target.src = "/assets/svgs/trash.svg";
                }}
              />
            </button>
          )}
          <button
            className="entry__action"
            onClick={(e) => {
              let inSpanish = false;
              if (e.ctrlKey || e.metaKey) {
                inSpanish = true;
              }
              return citeOne(article, jurisdictions, inSpanish).then((ret) =>
                setAlert(
                  "<strong>" +
                    article.s +
                    "'s</strong> article info copied to clipboard" +
                    (inSpanish ? " <em>(in Spanish)</em>" : "")
                )
              );
            }}
          >
            <img
              src="/assets/svgs/cite.svg"
              alt="Cite entry"
              title="Cite entry"
              width="100%"
              onMouseOver={(e) => {
                e.target.src = "/assets/svgs/cite--hover.svg";
              }}
              onMouseOut={(e) => {
                e.target.src = "/assets/svgs/cite.svg";
              }}
            />
          </button>
          <button
            className="entry__action"
            onClick={() => setReportArticle(article)}
          >
            <img
              src="/assets/svgs/report.svg"
              alt="Report error"
              title="Report error"
              width="100%"
              onMouseOver={(e) => {
                e.target.src = "/assets/svgs/report--hover.svg";
              }}
              onMouseOut={(e) => {
                e.target.src = "/assets/svgs/report.svg";
              }}
            />
          </button>
        </div>
      </div>

      <div
        className="child-entry-container"
        style={{
          ...(article.showDaughters || loading_daughters == article.id
            ? { display: "block" }
            : { display: "none" }),
        }}
      >
        {loading_daughters == article.id ? (
          <div className="loading-daughters-gif">
            <img src="/assets/img/loading.gif" />
          </div>
        ) : (
          article.daughters &&
          article.daughters.map((a) => (
            <ChildEntry
              article={a}
              sourceTypes={sourceTypes}
              addArticlesSelected={addArticlesSelected}
              removeArticlesSelected={removeArticlesSelected}
              setSharePopupOpen={setSharePopupOpen}
              setArticlesSelected={setArticlesSelected}
              articlesSelected={articlesSelected}
              reportArticle={reportArticle}
              setAlert={setAlert}
              setReportArticle={setReportArticle}
              saveFolderPopupOpen={saveFolderPopupOpen}
              setSaveFolderPopupOpen={setSaveFolderPopupOpen}
            />
          ))
        )}
      </div>
    </div>
  );
};
export default Entry;
