import React, { useRef } from "react";

export default function TestClipboard() {
  const textArea = useRef();
  const explanArea = useRef();
  function copyToClip(str) {
    function listener(e) {
      e.clipboardData.setData("text/html", str);
      e.clipboardData.setData("text/plain", str.replace(/(<([^>]+)>)/gi, ""));
      e.preventDefault();
    }
    document.addEventListener("copy", listener);
    let ret = document.execCommand("copy");
    document.removeEventListener("copy", listener);
    if (ret) return ret;
    else {
      if ("clipboard" in navigator && "ClipboardItem" in window) {
        const contentH = str;
        const blobH = new Blob([contentH], { type: "text/html" });
        const content = str.replace(/(<([^>]+)>)/gi, "");
        const blob = new Blob([content], { type: "text/plain" });
        const clipboardItemH = new window.ClipboardItem({
          "text/html": blobH,
          "text/plain": blob,
        });
        return navigator.clipboard.write([clipboardItemH]);
      } else {
        return false;
      }
    }
  }

  const testClip = (num) => {
    textArea.current.value = "";
    if (num === 1) {
      if (copyToClip("COPIED FROM <strong>TEST</strong> 1"))
        explanArea.current.innerHTML = "Copied using execCommand!";
      else explanArea.current.innerHTML = "execCommand not available";
      setTimeout(() => (explanArea.current.innerHTML = ""), 2000);
    } else {
      if ("clipboard" in navigator && "ClipboardItem" in window) {
        const contentH = "COPIED FROM <strong>TEST</strong> 2";
        const blobH = new Blob([contentH], { type: "text/html" });
        const content = "COPIED FROM TEST 2";
        const blob = new Blob([content], { type: "text/plain" });
        const clipboardItemH = new window.ClipboardItem({
          "text/html": blobH,
          "text/plain": blob,
        });
        explanArea.current.innerHTML = "Copied using clipboard!";
        return navigator.clipboard.write([clipboardItemH]);
      } else {
        explanArea.current.innerHTML = "clipboard not available";
      }
    }
  };
  return (
    <>
      <div style={{ margin: "auto", marginTop: "100px" }}>
        <div
          style={{ color: "red", textAlign: "center", marginBottom: "50px" }}
          ref={explanArea}
        ></div>
        Paste here:{" "}
        <input type="text" ref={textArea} style={{ border: "1px solid" }} />
      </div>
      <div style={{ margin: "auto", marginTop: "50px" }}>
        <a
          onClick={() => testClip(1)}
          style={{ cursor: "pointer", textDecoration: "underline" }}
        >
          TEST 1: CLICK HERE
        </a>{" "}
        -----
        <a
          onClick={() => testClip(2)}
          style={{ cursor: "pointer", textDecoration: "underline" }}
        >
          TEST 2: CLICK HERE
        </a>
      </div>
    </>
  );
}
